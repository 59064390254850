import { AfterViewInit, Component, OnInit, QueryList, Signal, ViewChildren, WritableSignal, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CdkAccordionItem } from '@angular/cdk/accordion';

import { TroopsBaseComponent } from '../../economics/troops-base/troops-base.component';
import { TroopTransactionEditComponent } from '../../economics/troop-transaction-edit/troop-transaction-edit.component';

import { PcfCounts, PcfService } from '../../services/pcf.service';
import { InformationStarSystemService } from '../../services/information-star-system.service';

import { InformationStarSystem } from '../../interfaces/information-star-system';
import { Transaction } from '../../interfaces/transaction';

@Component({
  selector: 'troop-recruiting',
  templateUrl: './troop-recruiting.component.html',
})
export class TroopRecruitingComponent extends TroopsBaseComponent implements OnInit, AfterViewInit {
  @ViewChildren(CdkAccordionItem) accordions!: QueryList<CdkAccordionItem>;

  private pcfService = inject(PcfService);
  private informationStarSystemService = inject(InformationStarSystemService);

  override pageTitle: string = "Planetary Control Forces (PCF)";
  override componentType = TroopTransactionEditComponent;

  showAll: boolean = false;
  pcfCountsByBodyId: Signal<PcfCounts>;
  starSystemsWithPopulations: WritableSignal<InformationStarSystem[]> = signal([]);

  constructor () {
    super();
    this.readOnly = this.race.readyForEconomicsTurn >= this.session.turnEdit();
    this.showAll = (this.campaign.turn <= this.session.turnEdit());
    console.log('TroopRecruitingComponent readOnly:', this.readOnly);

    this.pcfCountsByBodyId = toSignal<PcfCounts>(
      this.pcfService.getPcfCountsByBodyIdForRaceId(this.race._id)
    );

    effect(() => {
      let systemsSet = this.systemsSet();
      if (!systemsSet || systemsSet.length === 0) {
        return;
      }

      this.session.iAmBusy();
      this.informationStarSystemService.getInformationStarSystemForRaceId(this.race._id).subscribe(
        informationStarSystems => {
          this.starSystemsWithPopulations.set(informationStarSystems.filter(
            informationStarSystem => systemsSet.includes(informationStarSystem.starSystemId)
          ));
          this.session.iAmNotBusy();
        }
      );
    });
  };

  override ngOnInit () {
    super.ngOnInit();
  };

  ngAfterViewInit (): void {
    setTimeout(() => this.accordions.get(0).open(), 200);
  }
}
