import { inject, Component, Signal, effect, WritableSignal, signal, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { authorizedMarshal } from '../../auth/auth.guard';
import { ProjectService } from '../../services/project.service';
import { TableService } from '../../services/table.service';

import { TransactionBaseComponent } from '../../economics/transaction-base/transaction-base.component';

import { Transaction } from '../../interfaces/transaction';
import { Project } from '../../interfaces/project';

@Component({
  selector: 'economic-level-research',
  templateUrl: './economic-level-research.component.html',
})
export class EconomicLevelResearchComponent extends TransactionBaseComponent {
  pageTitle: string = "Economic Level Research";
  tableService = inject(TableService);
  projectService = inject(ProjectService);
  authorizedMarshal: boolean = false;
  skyMarshalMode: boolean = false;

  allResearchProjects: Signal<Project[]>;
  history: WritableSignal<Project[]> = signal([]);
  researchProject: WritableSignal<Project | undefined> = signal(undefined);
  hasNextLevelActive: boolean = false;
  theTransaction: WritableSignal<Transaction | undefined> = signal(undefined);

  canBeCancelled: Signal<boolean> = computed(() => {
    let canBeCancelled = false;
    let researchProject = this.researchProject();
    let theTransaction = this.theTransaction();
    if (researchProject && theTransaction) {
      let feesExpected = [0, researchProject.startUpFee, researchProject.startUpFee / 2];
      let index = feesExpected.indexOf(theTransaction.amount);
      canBeCancelled = index >= 0;
    }

    return canBeCancelled;
  });

  constructor () {
    super();
    this.transactionType = "research";
    this.authorizedMarshal = authorizedMarshal();

    this.allResearchProjects = toSignal<Project[]>(this.projectService.getProjectLevelsForRace(this.race._id));

    effect(() => {
      let projects = this.allResearchProjects();
      if (!projects || projects.length === 0) {
        return;
      }

      let history: Project[] = projects.filter((levelProject) => {
        let completed = levelProject.state === "completed";
        if (!completed) {
          this.researchProject.set(levelProject);
          this.hasNextLevelActive = true;
        }
        return completed;
      });
      this.history.set(history);
    }, { allowSignalWrites: true });

    effect(() => {
      let transactions = this.transactions();
      if (!transactions || transactions.length === 0) {
        this.theTransaction.set(undefined);
        return;
      }

      // if there is more than 1, there is a problem!!!
      this.theTransaction.set(transactions[0]);
    }, { allowSignalWrites: true });
  };

  override ngOnInit () {
    super.ngOnInit();
  };

  activateLevelResearch () {
    throw new Error('Method not implemented.');
  };

  start (startUpFactor = 1, monthlyFactor = 1) {
    let research = this.researchProject();
    // research.turnStarted = this.session.turnEdit();

    let transaction = Object.assign(this.newTransaction(), {
      locator: research.code,      // project code
      description: startUpFactor === 1 ?
        'Cost to start economic level research' :
        'Cost to restart economic level research',
      amount: research.startUpFee * startUpFactor
    });

    this.transactionService.saveTransaction(transaction).subscribe(
      savedTransaction => {
        // when transactions is updated, the 'effect' in constructor is triggered
        this.transactions.set([savedTransaction]);
      }
    );
  };

  cancel () {
    let transaction = this.theTransaction();

    if (transaction.amount === 0) {
      transaction.amount = this.researchProject().monthlyFee;
      this.transactionService.saveTransaction(transaction).subscribe(
        (savedTransaction) => this.transactions.set([savedTransaction])
      );
    }
    else {
      this.transactionService.deleteTransactionById(transaction._id).subscribe(
        () => this.transactions.set([] as Transaction[])
      );
    }
  };

  stop () {
    this.theTransaction().amount = 0;
    this.transactionService.saveTransaction(this.theTransaction()).subscribe(
      (savedTransaction) => this.transactions.set([savedTransaction])
    );
  };
};

// activateLevelResearch() {
//   if (!this.researchProject()) {
//     EconomySvc.findNextEconomicLevelResearchForRace(localData.race._id).then(
//       function (rdEconomicLevel) {
//         localData.hasNextLevelActive = true;
//         localData.current = rdEconomicLevel;
//         $timeout();
//       }
//     );
//   }
// };
